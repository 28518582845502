import { CustomDashboardType, getMeasure } from "../CustomDashReports";

export const Personnel: { [index: string]: CustomDashboardType } = {
  "Personnel > Overall Dealership > Personnel Expenses / head / month": {
    name: "Personnel > Overall Dealership > Personnel Expenses / head / month",
    department: "Personnel",
    category: "Overall Dealership",
    label: "Personnel Expenses / head / month",
    tableNumber: 3000,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses ph | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Overall Dealership > Salary & Direct Benefits / head / month": {
    name: "Personnel > Overall Dealership > Salary & Direct Benefits / head / month",
    department: "Personnel",
    category: "Overall Dealership",
    label: "Salary & Direct Benefits / head / month",
    tableNumber: 3000,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Salary and Direct Benefits ph | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Overall Dealership > Training / head / month": {
    name: "Personnel > Overall Dealership > Training / head / month",
    department: "Personnel",
    category: "Overall Dealership",
    label: "Training / head / month",
    tableNumber: 3000,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training ph | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Overall Dealership > Training / Productive / month": {
    name: "Personnel > Overall Dealership > Training / Productive / month",
    department: "Personnel",
    category: "Overall Dealership",
    label: "Training / Productive / month",
    tableNumber: 3000,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training p Productive | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Overall Dealership > Recruitment / head / month": {
    name: "Personnel > Overall Dealership > Recruitment / head / month",
    department: "Personnel",
    category: "Overall Dealership",
    label: "Recruitment / head / month",
    tableNumber: 3000,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Staff Recruitment ph | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > NV F&I > NV F&I Personnel Expenses / head / month": {
    name: "Personnel > NV F&I > NV F&I Personnel Expenses / head / month",
    department: "Personnel",
    category: "NV F&I",
    label: "NV F&I Personnel Expenses / head / month",
    tableNumber: 3001,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses ph | NewAftercareF&ISplit | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > NV F&I > NV F&I Salary & Direct Benefits / head / month": {
    name: "Personnel > NV F&I > NV F&I Salary & Direct Benefits / head / month",
    department: "Personnel",
    category: "NV F&I",
    label: "NV F&I Salary & Direct Benefits / head / month",
    tableNumber: 3001,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Salary and Direct Benefits ph | NewAftercareF&ISplit | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > NV F&I > NV F&I Training / head / month": {
    name: "Personnel > NV F&I > NV F&I Training / head / month",
    department: "Personnel",
    category: "NV F&I",
    label: "NV F&I Training / head / month",
    tableNumber: 3001,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training ph | NewAftercareF&ISplit | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > NV F&I > NV F&I Training / Productive / month": {
    name: "Personnel > NV F&I > NV F&I Training / Productive / month",
    department: "Personnel",
    category: "NV F&I",
    label: "NV F&I Training / Productive / month",
    tableNumber: 3001,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training p Productive | NewAftercareF&ISplit | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > NV F&I > NV F&I Recruitment / head / month": {
    name: "Personnel > NV F&I > NV F&I Recruitment / head / month",
    department: "Personnel",
    category: "NV F&I",
    label: "NV F&I Recruitment / head / month",
    tableNumber: 3001,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Staff Recruitment ph | NewAftercareF&ISplit | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > NV > NV F&I Personnel Expenses / head / month": {
    name: "Personnel > NV > NV F&I Personnel Expenses / head / month",
    department: "Personnel",
    category: "NV",
    label: "NV Personnel Expenses / head / month",
    tableNumber: 3002,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses ph | New | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > NV > NV F&I Salary & Direct Benefits / head / month": {
    name: "Personnel > NV > NV F&I Salary & Direct Benefits / head / month",
    department: "Personnel",
    category: "NV",
    label: "NV Salary & Direct Benefits / head / month",
    tableNumber: 3002,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Salary and Direct Benefits ph | New | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > NV > NV F&I Training / head / month": {
    name: "Personnel > NV > NV F&I Training / head / month",
    department: "Personnel",
    category: "NV",
    label: "NV Training / head / month",
    tableNumber: 3002,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training ph | New | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > NV > NV F&I Training / Productive / month": {
    name: "Personnel > NV > NV F&I Training / Productive / month",
    department: "Personnel",
    category: "NV",
    label: "NV Training / Productive / month",
    tableNumber: 3002,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training p Productive | New | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > NV > NV F&I Recruitment / head / month": {
    name: "Personnel > NV > NV F&I Recruitment / head / month",
    department: "Personnel",
    category: "NV",
    label: "NV Recruitment / head / month",
    tableNumber: 3002,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Staff Recruitment ph | New | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > UV F&I > UV F&I Personnel Expenses / head / month": {
    name: "Personnel > UV F&I > UV F&I Personnel Expenses / head / month",
    department: "Personnel",
    category: "UV F&I",
    label: "UV F&I Personnel Expenses / head / month",
    tableNumber: 3003,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses ph | UsedAftercareF&ISplit | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > UV F&I > UV F&I Salary & Direct Benefits / head / month": {
    name: "Personnel > UV F&I > UV F&I Salary & Direct Benefits / head / month",
    department: "Personnel",
    category: "UV F&I",
    label: "UV F&I Salary & Direct Benefits / head / month",
    tableNumber: 3003,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Salary and Direct Benefits ph | UsedAftercareF&ISplit | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > UV F&I > UV F&I Training / head / month": {
    name: "Personnel > UV F&I > UV F&I Training / head / month",
    department: "Personnel",
    category: "UV F&I",
    label: "UV F&I Training / head / month",
    tableNumber: 3003,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training ph | UsedAftercareF&ISplit | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > UV F&I > UV F&I Training / Productive / month": {
    name: "Personnel > UV F&I > UV F&I Training / Productive / month",
    department: "Personnel",
    category: "UV F&I",
    label: "UV F&I Training / Productive / month",
    tableNumber: 3003,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training p Productive | UsedAftercareF&ISplit | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > UV F&I > UV F&I Recruitment / head / month": {
    name: "Personnel > UV F&I > UV F&I Recruitment / head / month",
    department: "Personnel",
    category: "UV F&I",
    label: "UV F&I Recruitment / head / month",
    tableNumber: 3003,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Staff Recruitment ph | UsedAftercareF&ISplit | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > UV > UV F&I Personnel Expenses / head / month": {
    name: "Personnel > UV > UV F&I Personnel Expenses / head / month",
    department: "Personnel",
    category: "UV",
    label: "UV Personnel Expenses / head / month",
    tableNumber: 3004,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses ph | Used | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > UV > UV F&I Salary & Direct Benefits / head / month": {
    name: "Personnel > UV > UV F&I Salary & Direct Benefits / head / month",
    department: "Personnel",
    category: "UV",
    label: "UV Salary & Direct Benefits / head / month",
    tableNumber: 3004,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Salary and Direct Benefits ph | Used | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > UV > UV F&I Training / head / month": {
    name: "Personnel > UV > UV F&I Training / head / month",
    department: "Personnel",
    category: "UV",
    label: "UV Training / head / month",
    tableNumber: 3004,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training ph | Used | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > UV > UV F&I Training / Productive / month": {
    name: "Personnel > UV > UV F&I Training / Productive / month",
    department: "Personnel",
    category: "UV",
    label: "UV Training / Productive / month",
    tableNumber: 3004,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training p Productive | Used | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > UV > UV F&I Recruitment / head / month": {
    name: "Personnel > UV > UV F&I Recruitment / head / month",
    department: "Personnel",
    category: "UV",
    label: "UV Recruitment / head / month",
    tableNumber: 3004,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Staff Recruitment ph | Used | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > F&I > F&I Personnel Expenses / head / month": {
    name: "Personnel > F&I > F&I Personnel Expenses / head / month",
    department: "Personnel",
    category: "F&I",
    label: "F&I Personnel Expenses / head / month",
    tableNumber: 3005,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses ph | F&I Dept | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > F&I > F&I Salary & Direct Benefits / head / month": {
    name: "Personnel > F&I > F&I Salary & Direct Benefits / head / month",
    department: "Personnel",
    category: "F&I",
    label: "F&I Salary & Direct Benefits / head / month",
    tableNumber: 3005,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Salary and Direct Benefits ph | F&I Dept | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > F&I > F&I Training / head / month": {
    name: "Personnel > F&I > F&I Training / head / month",
    department: "Personnel",
    category: "F&I",
    label: "F&I Training / head / month",
    tableNumber: 3005,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training ph | F&I Dept | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > F&I > F&I Training / Productive / month": {
    name: "Personnel > F&I > F&I Training / Productive / month",
    department: "Personnel",
    category: "F&I",
    label: "F&I Training / Productive / month",
    tableNumber: 3005,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training p Productive | F&I Dept | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > F&I > F&I Recruitment / head / month": {
    name: "Personnel > F&I > F&I Recruitment / head / month",
    department: "Personnel",
    category: "F&I",
    label: "F&I Recruitment / head / month",
    tableNumber: 3005,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Staff Recruitment ph | F&I Dept | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > After Sales > After Sales Personnel Expenses / head / month": {
    name: "Personnel > After Sales > After Sales Personnel Expenses / head / month",
    department: "Personnel",
    category: "After Sales",
    label: "After Sales Personnel Expenses / head / month",
    tableNumber: 3006,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses ph | Aftersales | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > After Sales > After Sales Salary & Direct Benefits / head / month": {
    name: "Personnel > After Sales > After Sales Salary & Direct Benefits / head / month",
    department: "Personnel",
    category: "After Sales",
    label: "After Sales Salary & Direct Benefits / head / month",
    tableNumber: 3006,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Salary and Direct Benefits ph | Aftersales | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > After Sales > After Sales Training / head / month": {
    name: "Personnel > After Sales > After Sales Training / head / month",
    department: "Personnel",
    category: "After Sales",
    label: "After Sales Training / head / month",
    tableNumber: 3006,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training ph | Aftersales | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > After Sales > After Sales Training / Productive / month": {
    name: "Personnel > After Sales > After Sales Training / Productive / month",
    department: "Personnel",
    category: "After Sales",
    label: "After Sales Training / Productive / month",
    tableNumber: 3006,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training p Productive | Aftersales | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > After Sales > After Sales Recruitment / head / month": {
    name: "Personnel > After Sales > After Sales Recruitment / head / month",
    department: "Personnel",
    category: "After Sales",
    label: "After Sales Recruitment / head / month",
    tableNumber: 3006,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Staff Recruitment ph | Aftersales | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Parts > Parts Personnel Expenses / head / month": {
    name: "Personnel > Parts > Parts Personnel Expenses / head / month",
    department: "Personnel",
    category: "Parts",
    label: "Parts Personnel Expenses / head / month",
    tableNumber: 3007,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses ph | Parts | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Parts > Parts Salary & Direct Benefits / head / month": {
    name: "Personnel > Parts > Parts Salary & Direct Benefits / head / month",
    department: "Personnel",
    category: "Parts",
    label: "Parts Salary & Direct Benefits / head / month",
    tableNumber: 3007,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Salary and Direct Benefits ph | Parts | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Parts > Parts Training / head / month": {
    name: "Personnel > Parts > Parts Training / head / month",
    department: "Personnel",
    category: "Parts",
    label: "Parts Training / head / month",
    tableNumber: 3007,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training ph | Parts | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Parts > Parts Training / Productive / month": {
    name: "Personnel > Parts > Parts Training / Productive / month",
    department: "Personnel",
    category: "Parts",
    label: "Parts Training / Productive / month",
    tableNumber: 3007,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training p Productive | Parts | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Parts > Parts Recruitment / head / month": {
    name: "Personnel > Parts > Parts Recruitment / head / month",
    department: "Personnel",
    category: "Parts",
    label: "Parts Recruitment / head / month",
    tableNumber: 3007,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Staff Recruitment ph | Parts | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Service > Service Personnel Expenses / head / month": {
    name: "Personnel > Service > Service Personnel Expenses / head / month",
    department: "Personnel",
    category: "Service",
    label: "Service Personnel Expenses / head / month",
    tableNumber: 3008,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses ph | ServiceQuickService | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Service > Service Salary & Direct Benefits / head / month": {
    name: "Personnel > Service > Service Salary & Direct Benefits / head / month",
    department: "Personnel",
    category: "Service",
    label: "Service Salary & Direct Benefits / head / month",
    tableNumber: 3008,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Salary and Direct Benefits ph | ServiceQuickService | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Service > Service Training / head / month": {
    name: "Personnel > Service > Service Training / head / month",
    department: "Personnel",
    category: "Service",
    label: "Service Training / head / month",
    tableNumber: 3008,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training ph | ServiceQuickService | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Service > Service Training / Productive / month": {
    name: "Personnel > Service > Service Training / Productive / month",
    department: "Personnel",
    category: "Service",
    label: "Service Training / Productive / month",
    tableNumber: 3008,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training p Productive | ServiceQuickService | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Service > Service Recruitment / head / month": {
    name: "Personnel > Service > Service Recruitment / head / month",
    department: "Personnel",
    category: "Service",
    label: "Service Recruitment / head / month",
    tableNumber: 3008,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Staff Recruitment ph | ServiceQuickService | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Body Shop > Body Shop Personnel Expenses / head / month": {
    name: "Personnel > Body Shop > Body Shop Personnel Expenses / head / month",
    department: "Personnel",
    category: "Body Shop",
    label: "Body Shop Personnel Expenses / head / month",
    tableNumber: 3009,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses ph | Body Shop | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Body Shop > Body Shop Salary & Direct Benefits / head / month": {
    name: "Personnel > Body Shop > Body Shop Salary & Direct Benefits / head / month",
    department: "Personnel",
    category: "Body Shop",
    label: "Body Shop Salary & Direct Benefits / head / month",
    tableNumber: 3009,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Salary and Direct Benefits ph | Body Shop | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Body Shop > Body Shop Training / head / month": {
    name: "Personnel > Body Shop > Body Shop Training / head / month",
    department: "Personnel",
    category: "Body Shop",
    label: "Body Shop Training / head / month",
    tableNumber: 3009,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training ph | Body Shop | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Body Shop > Body Shop Training / Productive / month": {
    name: "Personnel > Body Shop > Body Shop Training / Productive / month",
    department: "Personnel",
    category: "Body Shop",
    label: "Body Shop Training / Productive / month",
    tableNumber: 3009,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training p Productive | Body Shop | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Body Shop > Body Shop Recruitment / head / month": {
    name: "Personnel > Body Shop > Body Shop Recruitment / head / month",
    department: "Personnel",
    category: "Body Shop",
    label: "Body Shop Recruitment / head / month",
    tableNumber: 3009,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Staff Recruitment ph | Body Shop | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Other Dept. > Other Dept. Personnel Expenses / head / month": {
    name: "Personnel > Other Dept. > Other Dept. Personnel Expenses / head / month",
    department: "Personnel",
    category: "Other Dept.",
    label: "Other Dept. Personnel Expenses / head / month",
    tableNumber: 3010,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses ph | OtherDept | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Other Dept. > Other Dept. Salary & Direct Benefits / head / month": {
    name: "Personnel > Other Dept. > Other Dept. Salary & Direct Benefits / head / month",
    department: "Personnel",
    category: "Other Dept.",
    label: "Other Dept. Salary & Direct Benefits / head / month",
    tableNumber: 3010,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Salary and Direct Benefits ph | OtherDept | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Other Dept. > Other Dept. Training / head / month": {
    name: "Personnel > Other Dept. > Other Dept. Training / head / month",
    department: "Personnel",
    category: "Other Dept.",
    label: "Other Dept. Training / head / month",
    tableNumber: 3010,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training ph | OtherDept | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Other Dept. > Other Dept. Training / Productive / month": {
    name: "Personnel > Other Dept. > Other Dept. Training / Productive / month",
    department: "Personnel",
    category: "Other Dept.",
    label: "Other Dept. Training / Productive / month",
    tableNumber: 3010,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training p Productive | OtherDept | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Other Dept. > Other Dept. Recruitment / head / month": {
    name: "Personnel > Other Dept. > Other Dept. Recruitment / head / month",
    department: "Personnel",
    category: "Other Dept.",
    label: "Other Dept. Recruitment / head / month",
    tableNumber: 3010,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Staff Recruitment ph | OtherDept | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Admin > Admin Personnel Expenses / head / month": {
    name: "Personnel > Admin > Admin Personnel Expenses / head / month",
    department: "Personnel",
    category: "Admin",
    label: "Admin Personnel Expenses / head / month",
    tableNumber: 3011,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses ph | Admin | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Admin > Admin Salary & Direct Benefits / head / month": {
    name: "Personnel > Admin > Admin Salary & Direct Benefits / head / month",
    department: "Personnel",
    category: "Admin",
    label: "Admin Salary & Direct Benefits / head / month",
    tableNumber: 3011,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Salary and Direct Benefits ph | Admin | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Admin > Admin Training / head / month": {
    name: "Personnel > Admin > Admin Training / head / month",
    department: "Personnel",
    category: "Admin",
    label: "Admin Training / head / month",
    tableNumber: 3011,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training ph | Admin | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Admin > Admin Training / Productive / month": {
    name: "Personnel > Admin > Admin Training / Productive / month",
    department: "Personnel",
    category: "Admin",
    label: "Admin Training / Productive / month",
    tableNumber: 3011,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Training p Productive | Admin | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Personnel > Admin > Admin Recruitment / head / month": {
    name: "Personnel > Admin > Admin Recruitment / head / month",
    department: "Personnel",
    category: "Admin",
    label: "Admin Recruitment / head / month",
    tableNumber: 3011,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Staff Recruitment ph | Admin | All Brand | All Model | | AVG",
    canAverage: true,
  },
};
